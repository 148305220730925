import { http } from '@/http/axios.js';
import { http_limit } from '@/http/axios_limit.js'
import axios from 'axios';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { dateFormat } from '@/utils/index.js';
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js';
import { Message } from 'element-ui';

// 列表
export function getListAPI(params) {
    return http({
        url: '/admin/order/page',
        method: 'get',
        params,
    });
}
// 详情
export function getDetailAPI(orderNo) {
    return http({
        url: '/admin/order/detail',
        method: 'get',
        params: { orderNo },
    });
}

// 发货
export function handleDelieryAPI(data) {
    return http({
        url: '/admin/order/delivery',
        method: 'post',
        data,
    });
}
// 发货-门店
export function handleDelieryStoreAPI(data) {
    return http({
        url: '/admin/order/pickup',
        method: 'post',
        data,
    });
}
// 虚拟发货
export function handleDelieryVirtualAPI(data) {
    return http({
        url: '/admin/order/virtualDelivery',
        method: 'post',
        data,
    });
}
// 订单账单列表
export function getOrderBillListAPI(orderNo) {
    return http({
        url: '/admin/order/bill/list',
        method: 'get',
        params: { orderNo },
    });
}
// 订单取消
export function handleOrderCancelAPI(orderNo) {
    return http({
        url: '/admin/order/cancel',
        method: 'post',
        data: { orderNo },
    });
}
// 下载合同
export function downloadOrderContractAPI(orderNo) {
    return http({
        url: '/admin/order/contract/download',
        method: 'post',
        data: { orderNo },
    });
}

//门店结算金额明细
export function settlementAmountDetails(params) {
    return http({ url: '/admin/order/store/settle/info', method: 'get', params });
}

export function downloadFileByUrl(url) {
    console.log(123);
    showScreenLoading();
    let link = document.createElement('a');
    //创建一个a标签
    link.style.display = 'none';
    //将a标签隐藏
    link.href = url;
    //给a标签添加下载链接  "域名+接口"  safe是一个动态的域名  后面的接口替换成你自己的下载接口
    // link.setAttribute('download', dateFormat())
    link.download = '123.pdf';
    // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
    document.body.appendChild(link);
    //将上面创建的a标签加入到body的尾部
    link.click();
    document.body.removeChild(link);
    //执行a标签
    hideScreenLoading();
}
// 统计
export function getOrderStaticAPI() {
    return http({
        url: '/admin/order/status/stat',
        method: 'get',
    });
}
// 三方
export function getOrderRiskAPI(memberId) {
    return http({
        url: '/admin/member/openRisk/data',
        method: 'get',
        params: { memberId },
    });
}
// 机审
export function getRiskReviewAPI(orderNo) {
    return http({
        url: '/admin/order/riskReview/data',
        method: 'get',
        params: { orderNo },
    });
}
// 合同详情
export function getContractDetailAPI(orderNo) {
    return http({
        url: '/admin/order/contract/detail',
        method: 'get',
        params: { orderNo },
    });
}
// 征信报告
export function getCreditReportAPI(orderNo) {
    return http({
        url: '/admin/order/credit/report',
        method: 'get',
        params: { orderNo },
    });
}
// 全部导出
export function DownloadAPI(data) {
    showScreenLoading();
    axios({
        method: 'post',
        url: '/api/admin/order/export',
        responseType: 'blob',
        data,
        headers: {
            Authorization: getToken() || '',
        },
    })
        .then(async (res) => {
            hideScreenLoading();
            let data = res.data;
            let url = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            let filename = `订单列表${dateFormat()}.xlsx`;
            a.setAttribute('download', decodeURIComponent(filename));
            document.body.appendChild(a);
            a.click(); //执行下载
            window.URL.revokeObjectURL(a.href); //释放url
            document.body.removeChild(a); //释放标签
        })
        .catch((error) => {
            hideScreenLoading();
        });
}

// 公证
export function getNotaryApplyAPI(orderNo) {
    return http({
        url: '/admin/order/notary/apply',
        method: 'post',
        data: { orderNo },
    });
}
// 证书
export function getNotaryFileAPI(orderNo) {
    return http({
        url: '/admin/order/notary/file',
        method: 'post',
        data: { orderNo },
    });
}

// 下载文件1
export function downFile(url, name) {
    getBlob(url).then((blob) => {
        saveAs(blob, name);
    });
}
function getBlob(url) {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            if (xhr.status == 200) {
                resolve(xhr.response);
            }
        };
        xhr.send();
    });
}
function saveAs(blob, name) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
}
// 下载文件2
export function downloadPDF(pdfUrl, name) {
    showScreenLoading();
    axios({
        url: pdfUrl,
        method: 'GET',
        responseType: 'blob', // 必须指定为blob类型才能下载
    }).then(
        (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            hideScreenLoading();
        },
        (reason) => {
            hideScreenLoading();
            Message.error('下载失败！');
        }
    );
}

// 逾期上报
export function handleOverduceAPI(data) {
    return http({
        url: '/admin/order/credit/overdue/submit',
        method: 'post',
        data,
    });
}
// 完成订单
export function handleCompleteAPI(data) {
    return http({
        url: '/admin/order/complete',
        method: 'post',
        data,
    });
}
// 提前归还
export function handleAheadReturnAPI(data) {
    return http({
        url: '/admin/order/aheadReturn',
        method: 'post',
        data,
    });
}
// 首付明细
export function getOfflinePayDetailAPI(orderNo) {
    return http({
        url: '/admin/order/prepay/detail',
        method: 'get',
        params: { orderNo },
    });
}
// 提交首付
export function submitOfflinePayAPI(orderNo) {
    return http({
        url: '/admin/order/prepay/offlinePay',
        method: 'post',
        data: { orderNo },
    });
}
// 提交首付
export function getKamiDetailAPI(orderNo) {
    return http({
        url: '/admin/order/card/info',
        method: 'get',
        params: { orderNo },
    });
}
// 撞库
export function hitAPI(orderNo) {
    return http({
        url: '/admin/order/roster/hit',
        method: 'post',
        data: { orderNo },
    });
}
// 查询订单售后状态
export function getAfterSaleStatusAPI(orderNo) {
    return http({
        url: '/admin/order/afterSale/status',
        method: 'get',
        params: { orderNo },
    });
}
// 更新订单售后状态
export function submitAfterSaleStatusAPI(data) {
    return http({
        url: '/admin/order/afterSale/status/edit',
        method: 'post',
        data,
    });
}
// 查询设备状态
export function getDeviceStatusAPI(orderNo) {
    return http({
        url: '/admin/order/device/status',
        method: 'get',
        params: { orderNo },
    });
}
// 更新设备状态
export function submitDeviceStatusAPI(data) {
    return http({
        url: '/admin/order/device/status/edit',
        method: 'post',
        data,
    });
}
export function getOrderReviewDetailAPI(orderNo) {
    return http({
        url: '/admin/order/review/detail',
        method: 'get',
        params: { orderNo },
    });
}

// 线下签收
export function submitConfirmReceiveAPI(data) {
    return http({
        url: '/admin/order/receive',
        method: 'post',
        data,
    });
}

export function importOrderExcelAPI(data) {
    return http_limit({
        url: '/admin/order/excel/import',
        method: 'post',
        data
    })
}
